// firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBK-gprfy0e33l2iP7fhNlyG-Sp-oHsSOw",
  authDomain: "bazarr-442116.firebaseapp.com",
  projectId: "bazarr-442116",
  storageBucket: "bazarr-442116.firebasestorage.app",
  messagingSenderId: "890728989290",
  appId: "1:890728989290:web:ac1808d35cd54b1cda0013",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { auth };
