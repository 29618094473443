import React, { useState } from "react";
import UploadFile from "./UploadFile";
import SelectColumns from "./SelectColumns";
import Confirmation from "./Confirmation";

function Analyzer() {
  const [step, setStep] = useState("upload");
  const [fileHeaders, setFileHeaders] = useState([]);
  const [recipientEmail, setRecipientEmail] = useState("");

  const handleFileUpload = (headers) => {
    setFileHeaders(headers);
    setStep("selectColumns");
  };

  const handleColumnSelection = (email) => {
    setRecipientEmail(email);
    setStep("confirmation");
  };

  return (
    <div className="app-container">
      {step === "upload" && <UploadFile onFileUploaded={handleFileUpload} />}
      {step === "selectColumns" && (
        <SelectColumns headers={fileHeaders} onSubmit={handleColumnSelection} />
      )}
      {step === "confirmation" && <Confirmation email={recipientEmail} />}
    </div>
  );
}

export default Analyzer;
