// Navbar.js
import React from "react";
import { Menu, Layout } from "antd";
import {
  HomeOutlined,
  AppstoreOutlined,
  FileOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  QrcodeOutlined,
  ColumnWidthOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

const { SubMenu } = Menu;
const { Sider } = Layout;

const Navbar = () => {
  const location = useLocation();

  // Function to determine the selected keys based on current path
  const getSelectedKeys = () => {
    const path = location.pathname;
    switch (path) {
      case "/":
        return ["/"];
      case "/product-sourcing":
        return ["/product-sourcing"];
      case "/daily-deals":
        return ["/daily-deals"];
      case "/analyzer-tool":
        return ["/analyzer-tool"];
      case "/pricing-plans":
        return ["/pricing-plans"];
      // Add more cases as needed for other routes
      default:
        return [""]; // No selection
    }
  };

  const selectedKeys = getSelectedKeys();

  return (
    <Sider width={"20%"} style={{ height: "100vh", position: "fixed" }}>
      <Menu
        mode="inline"
        defaultOpenKeys={["dashboard"]}
        selectedKeys={selectedKeys}
        className="side-nav-menu"
        style={{ height: "100%", borderRight: 0 }}
      >
        {/* Tools Menu */}
        <SubMenu key="dashboard" icon={<HomeOutlined />} title="Tools">
          <Menu.Item key="/daily-deals" icon={<ShoppingCartOutlined />}>
            <Link to="/daily-deals">Daily Deals</Link>
          </Menu.Item>
          <Menu.Item key="/product-sourcing" icon={<ShoppingCartOutlined />}>
            <Link to="/product-sourcing">Product Sourcing</Link>
          </Menu.Item>
          <Menu.Item key="/analyzer-tool" icon={<QrcodeOutlined />}>
            <Link to="/analyzer-tool">Analyzer Tool</Link>
          </Menu.Item>
          <Menu.Item key="UPC2ASIN" icon={<ColumnWidthOutlined />}>
            <Link to="/asin-upc-converter">UPC ↔ ASIN Converter</Link>
          </Menu.Item>
        </SubMenu>

        {/* Agents Menu */}
        <SubMenu key="Agents" icon={<AppstoreOutlined />} title="Agents">
          <Menu.Item key="Agents:Agent1" disabled>
            Agent 1 (soon)
          </Menu.Item>
          <Menu.Item key="Agents:Agent2" disabled>
            Agent 2 (soon)
          </Menu.Item>
          <Menu.Item key="Agents:Agent3" disabled>
            Agent 3 (soon)
          </Menu.Item>
        </SubMenu>

        {/* Plan & Pricing Menu */}
        <SubMenu key="Pricing" icon={<FileOutlined />} title="Plans & Pricing">
          <Menu.Item key="/pricing-plans">
            <Link to="/pricing-plans">Pricing Plans</Link>
          </Menu.Item>
          <Menu.Item key="Pricing:planB" disabled>
            Plan B (soon)
          </Menu.Item>
          <Menu.Item key="Pricing:planC" disabled>
            Plan C (soon)
          </Menu.Item>
        </SubMenu>

        {/* Authentication Menu */}
        <SubMenu
          key="authentication"
          icon={<UserOutlined />}
          title="Authentication"
        >
          <Menu.Item key="/auth/register" disabled>
            <Link to="/auth/register">Register</Link>
          </Menu.Item>
          <Menu.Item key="/auth/login" disabled>
            <Link to="/auth/login">Login</Link>
          </Menu.Item>
          <Menu.Item key="/auth/forgot-password" disabled>
            <Link to="/auth/forgot-password">Forgot Password</Link>
          </Menu.Item>
          <Menu.Item key="/auth/reset-password" disabled>
            <Link to="/auth/reset-password">Reset Password</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default Navbar;
