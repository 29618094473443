// src/components/PricingPlans.js

import React, { useState, useRef } from "react";
import {
  Card,
  Col,
  Row,
  Button,
  Typography,
  List,
  Tag,
  Tooltip,
  Tour,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons"; // Importing icons

const { Title, Text } = Typography;

// Master Feature List
const masterFeatures = [
  "Daily Deals",
  "UPC to ASIN",
  "Product Sourcing",
  "Automatic Product Analysis - 1k SKUs per day",
  "Automatic Product Analysis - 3k SKUs per day",
  "Automatic Product Analysis - 7k SKUs per day",
  "Personal connection to our experts",
  "Exclusive services & opportunities",
  "Purchases groups",
];

// Updated Pricing Data
const pricingData = [
  {
    title: "Free Week",
    price: "$0",
    duration: "1 week",
    trial: "1 week free trial\nValid for one week",
    features: {
      "Daily Deals": true,
      "UPC to ASIN": true,
      "Automatic Product Analysis - 1k SKUs per day": true,
      "Automatic Product Analysis - 3k SKUs per day": false,
      "Automatic Product Analysis - 7k SKUs per day": false,
      "Product Sourcing": true,
      "Personal connection to our experts": false,
      "Exclusive services & opportunities": false,
      "Purchases groups": false,
    },
    buttonText: "Start Free Trial",
    buttonType: "default",
  },
  {
    title: "Starter",
    price: "$19",
    duration: "Every month",
    trial: "14 day free trial",
    features: {
      "Daily Deals": true,
      "UPC to ASIN": true,
      "Product Sourcing": false,
      "Automatic Product Analysis - 1k SKUs per day": true,
      "Automatic Product Analysis - 3k SKUs per day": false,
      "Automatic Product Analysis - 7k SKUs per day": false,
      "Personal connection to our experts": false,
      "Exclusive services & opportunities": false,
      "Purchases groups": false,
    },
    buttonText: "Start Free Trial",
    buttonType: "default",
  },
  {
    title: "Basic",
    price: "$39",
    duration: "Every month",
    trial: "14 day free trial",
    features: {
      "Daily Deals": true,
      "UPC to ASIN": true,
      "Automatic Product Analysis - 1k SKUs per day": false,
      "Automatic Product Analysis - 3k SKUs per day": true,
      "Automatic Product Analysis - 7k SKUs per day": false,
      "Product Sourcing": true,
      "Personal connection to our experts": false,
      "Exclusive services & opportunities": false,
      "Purchases groups": false,
    },
    buttonText: "Start Free Trial",
    buttonType: "default",
  },
  {
    title: "Pro",
    price: "$59",
    duration: "Every month",
    trial: "14 day free trial",
    features: {
      "Daily Deals": true,
      "UPC to ASIN": true,
      "Automatic Product Analysis - 1k SKUs per day": false,
      "Automatic Product Analysis - 3k SKUs per day": false,
      "Automatic Product Analysis - 7k SKUs per day": true,
      "Product Sourcing": true,
      "Personal connection to our experts": true,
      "Exclusive services & opportunities": true,
      "Purchases groups": true,
    },
    buttonText: "Start Free Trial",
    buttonType: "primary",
    isFeatured: true, // Highlight this plan
  },
];

const PricingPlans = () => {
  const [isTourOpen, setIsTourOpen] = useState(false);

  // Create refs for each plan title
  const starterTitleRef = useRef(null);
  const basicTitleRef = useRef(null);
  const proTitleRef = useRef(null);
  const freeWeekTitleRef = useRef(null);

  // Define Tour Steps
  const steps = [
    {
      title: "Starter Plan",
      description:
        "Our Starter plan offers essential features to get you started.",
      target: () => starterTitleRef.current,
    },
    {
      title: "Basic Plan",
      description:
        "The Basic plan includes additional features for growing businesses.",
      target: () => basicTitleRef.current,
    },
    {
      title: "Pro Plan",
      description:
        "The Pro plan is our most popular choice, offering comprehensive features.",
      target: () => proTitleRef.current,
    },
    {
      title: "Free Week Trial",
      description:
        "Try any of our plans for a free week to see what fits you best.",
      target: () => freeWeekTitleRef.current,
    },
  ];

  const handleStartTrial = (planTitle) => {
    // Implement your logic here, e.g., navigate to signup page
    console.log(`Starting free trial for ${planTitle}`);
    // Example: Navigate using React Router
    // history.push(`/signup?plan=${planTitle}`);
  };

  return (
    <div className="pricing-plans-container">
      {/* Tour Trigger Button */}
      <div>
        <Button
          className="button-tour"
          type="default"
          onClick={() => setIsTourOpen(true)}
        >
          Explore Our Plans
        </Button>
      </div>

      {/* Tour Component */}
      <Tour
        open={isTourOpen}
        onClose={() => setIsTourOpen(false)}
        steps={steps}
        placement="right"
        mask={true}
        maskClosable={true}
        closeIcon={<PlusOutlined />} // Optional: Customize the close icon
      />

      <Title level={2} className="pricing-title">
        Choose Your Plan
      </Title>
      <Row
        gutter={[24, 24]} // Increased gutter for better spacing
        justify="center"
        align="top"
      >
        {pricingData.map((plan, index) => {
          // Generate a list of features to display for this plan
          const filteredFeatures = masterFeatures.filter((feature) => {
            if (feature.startsWith("Automatic Product Analysis")) {
              return plan.features[feature] === true;
            }
            return true;
          });

          // Determine which ref to assign based on plan title
          let titleRef = null;
          switch (plan.title) {
            case "Starter":
              titleRef = starterTitleRef;
              break;
            case "Basic":
              titleRef = basicTitleRef;
              break;
            case "Pro":
              titleRef = proTitleRef;
              break;
            case "Free Week":
              titleRef = freeWeekTitleRef;
              break;
            default:
              titleRef = null;
          }

          return (
            <Col
              key={index}
              xs={24} // 1 column on extra small screens
              sm={12} // 2 columns on small screens
              md={12} // 2 columns on medium screens
              lg={6} // 4 columns on large screens
              xl={6} // 4 columns on extra large screens
            >
              <Card
                className={`pricing-card ${plan.isFeatured ? "featured" : ""}`}
                hoverable
                bordered={plan.isFeatured ? true : false}
              >
                {plan.isFeatured && (
                  <Tag color="blue" className="featured-tag">
                    Most Popular
                  </Tag>
                )}
                <Title ref={titleRef} level={3} className="plan-title">
                  {plan.title}
                </Title>
                <Text className="plan-price">
                  {plan.price} <Text type="secondary">{plan.duration}</Text>
                </Text>
                <Text className="plan-trial">
                  {plan.trial.split("\n").map((line, idx) => (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Text>
                <List
                  size="small"
                  dataSource={filteredFeatures}
                  renderItem={(feature) => {
                    const isSKUFeature = feature.startsWith(
                      "Automatic Product Analysis"
                    );
                    if (isSKUFeature) {
                      // Display only included SKU feature with a '+' icon
                      return (
                        <List.Item className="plan-feature-item">
                          <PlusOutlined className="feature-icon included" />
                          <span className="feature-text included-text">
                            {feature}
                          </span>
                        </List.Item>
                      );
                    } else {
                      // Display non-SKU features with '+' or '-' based on inclusion
                      const isIncluded = plan.features[feature] === true;
                      return (
                        <List.Item className="plan-feature-item">
                          {isIncluded ? (
                            <PlusOutlined className="feature-icon included" />
                          ) : (
                            <MinusOutlined className="feature-icon excluded" />
                          )}
                          <span
                            className={`feature-text ${
                              isIncluded ? "included-text" : "excluded-text"
                            }`}
                          >
                            {feature}
                          </span>
                        </List.Item>
                      );
                    }
                  }}
                  className="plan-features"
                />
                <Tooltip title={`Sign up for the ${plan.title} plan`}>
                  <Button
                    type={plan.buttonType}
                    className={`plan-button plan-button-${plan.buttonType}`}
                    block
                    aria-label={`Start free trial for the ${plan.title} plan`}
                    onClick={() => handleStartTrial(plan.title)}
                  >
                    {plan.buttonText}
                  </Button>
                </Tooltip>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Text className="footer-note">
        14 days free trial & free cancellation
      </Text>
    </div>
  );
};

export default PricingPlans;
