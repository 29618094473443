import React, { useState } from "react";
import {
  Form,
  Select,
  Input,
  Button,
  Typography,
  message,
  Divider,
} from "antd";

const { Title } = Typography;

function SelectColumns({ headers, onSubmit }) {
  const [asinColumn, setAsinColumn] = useState("");
  const [priceColumn, setPriceColumn] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    if (asinColumn && priceColumn && email) {
      onSubmit(email);
      message.success("Columns selected successfully!");
    } else {
      message.error("Please fill in all fields.");
    }
  };

  return (
    <div className="select-columns-container" style={{ background: "#f0f2f5" }}>
      <div className="title-container">
        <Title level={1} style={{ color: "#006eff" }}>
          Select Columns
        </Title>
      </div>
      <Divider />
      <Form
        layout="vertical"
        style={{
          maxWidth: "400px",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <Form.Item label="Select ASIN/UPC Column" required>
          <Select
            placeholder="Select a column"
            onChange={(value) => setAsinColumn(value)}
          >
            {headers.map((header, index) => (
              <Select.Option key={index} value={header}>
                {header}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Select BUY PRICE Column" required>
          <Select
            placeholder="Select a column"
            onChange={(value) => setPriceColumn(value)}
          >
            {headers.map((header, index) => (
              <Select.Option key={index} value={header}>
                {header}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Recipient Email" required>
          <Input
            type="email"
            placeholder="Enter recipient email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Button
          type="primary"
          onClick={handleSubmit}
          style={{ marginTop: "20px" }}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default SelectColumns;
