import React from "react";
import { Layout, Typography } from "antd";

const { Footer: AntFooter } = Layout;
const { Text } = Typography;

const Footer = () => {
  return (
    <AntFooter className="custom-footer">
      <div className="footer-content">
        {/* Left Side: Copyright */}
        <Text className="footer-text">
          © {new Date().getFullYear()} bazarr Inc. All Rights Reserved
        </Text>

        {/* Right Side: Contact Email */}
        <Text className="footer-email">
          <a href="mailto:Hello@bazarr.ai">Hello@bazarr.ai</a>
        </Text>
      </div>
    </AntFooter>
  );
};

export default Footer;
