import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Divider, Typography, Upload, message } from "antd";

const { Title, Text } = Typography;
const { Dragger } = Upload;

function UploadFile({ onFileUploaded }) {
  const props = {
    name: "file",
    multiple: false, // Restrict to single file
    beforeUpload: (file) => {
      const isValidFile =
        file.name.endsWith(".csv") || file.name.endsWith(".xlsx");
      if (!isValidFile) {
        message.error("Please upload a valid CSV or XLSX file.");
      }
      return isValidFile || Upload.LIST_IGNORE;
    },
    customRequest: ({ file, onSuccess }) => {
      // Simulated file reading
      setTimeout(() => {
        const headers = ["ASIN", "Price Per Unit", "Other Column"]; // Example headers
        onFileUploaded(headers);
        onSuccess("ok");
        message.success(`${file.name} file uploaded successfully.`);
      }, 1000);
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div className="upload-container" style={{ background: "#f0f2f5" }}>
      <div className="title-container">
        <Title level={1} style={{ color: "#006eff" }}>
          <InboxOutlined className="title-icon" />
          Upload File
        </Title>
      </div>
      <Divider />
      <br />
      <Dragger {...props} style={{ marginTop: "20px", padding: "20px" }}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined style={{ color: "#006eff", fontSize: "36px" }} />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-text">
          * Ensure the file includes ASIN/UPC and price per unit columns.
        </p>
        <p className="ant-upload-hint">
          Only CSV or XLSX files are supported. Please do not upload restricted
          data.
        </p>
        <p className="ant-upload-hint">
          Processing time varies between 1 hour and 24 hours, depending on file
          size.
        </p>
      </Dragger>
    </div>
  );
}

export default UploadFile;
