// src/components/Header.js
import React from "react";
import { Layout, Typography, Button, Dropdown, Menu, Tooltip } from "antd";
import {
  BellOutlined,
  SearchOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";

const { Header: AntHeader } = Layout;
const { Link, Text } = Typography;

const Header = () => {
  //Logout function
  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out:", error.message);
    }
  };

  // User menu for Dropdown
  const userMenu = (
    <Menu>
      <Menu.Item key="profile">
        <a href="#profile">Your Profile</a>
      </Menu.Item>
      <Menu.Item key="settings">
        <a href="#settings">Settings</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        {auth.currentUser && <button onClick={handleLogout}>Logout</button>}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="header">
      {/* Brand */}
      <Button type="link" href="/">
        <img src="./logo.png" alt="logo" className="header-logo" />
        <span className="header-title">| Dashboard</span>
      </Button>
      <div className="spacer" />

      {/* Navigation Buttons */}
      <div aria-label="Secondary" className="header-features">
        {/* Notification Button */}
        <Tooltip title="Notifications">
          <Button
            className="header-button"
            type="text"
            icon={<BellOutlined style={{ fontSize: "1.25rem" }} />}
          />
        </Tooltip>

        {/* Search Button */}
        <Tooltip title="Search">
          <Button
            className="header-button"
            type="text"
            icon={<SearchOutlined style={{ fontSize: "1.25rem" }} />}
            aria-label="Search"
          />
        </Tooltip>

        {/* Settings Button */}
        <Tooltip title="Settings">
          <Button
            className="header-button"
            type="text"
            icon={<SettingOutlined style={{ fontSize: "1.25rem" }} />}
            aria-label="Settings"
          />
        </Tooltip>

        {/* User Menu */}
        <Dropdown overlay={userMenu} placement="bottomRight" arrow>
          <Button
            className="header-button"
            type="text"
            icon={<UserOutlined style={{ fontSize: "1.25rem" }} />}
            aria-label="User menu"
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
