import React, { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import ProductSourcing from "./components/ProductSourcing";
import Footer from "./components/Footer";
import { Layout, Space } from "antd";
import DailyDeals from "./components/DailyDeals";
import Welcome from "./components/Welcome";
import Analyzer from "./components/Analyzer";
import PricingPlans from "./components/PricingPlans";
import Login from "./components/Login";
import Signup from "./components/Signup";
import AsinUpcConverter from "./components/AsinUpcConverter";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";

const { Header: AntHeader, Footer: AntFooter, Sider, Content } = Layout;

function App() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="App">
      <Router>
        <Layout>
          <Header />
          <Layout className="layout-first">
            {user && (
              <Sider
                width={"20%"}
                style={{ position: "fixed", height: "100%" }}
              >
                <Navbar />
              </Sider>
            )}
            <Layout className="layout-second">
              <Content className="content">
                <Routes>
                  {!user ? (
                    <>
                      <Route path="/login" element={<Login />} />
                      <Route path="/signup" element={<Signup />} />
                      {/* Redirect to login if user is not authenticated */}
                      <Route
                        path="*"
                        element={<Navigate to="/login" replace />}
                      />
                    </>
                  ) : (
                    <>
                      <Route path="/" element={<Welcome />} />
                      <Route
                        path="/product-sourcing"
                        element={<ProductSourcing />}
                      />
                      <Route
                        path="/asin-upc-converter"
                        element={<AsinUpcConverter />}
                      />
                      <Route path="/daily-deals" element={<DailyDeals />} />
                      <Route path="/analyzer-tool" element={<Analyzer />} />
                      <Route path="/pricing-plans" element={<PricingPlans />} />
                      {/* Redirect unknown routes to the Welcome page */}
                      <Route path="*" element={<Navigate to="/" replace />} />
                    </>
                  )}
                </Routes>
              </Content>
            </Layout>
          </Layout>
          <Footer />
        </Layout>
      </Router>
    </div>
  );
}

export default App;
