import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Button,
  Drawer,
  Collapse,
  Tooltip,
  Slider,
  Row,
  Col,
  InputNumber,
  Form,
  Select,
  Tour,
  Typography,
  Divider,
} from "antd";
import {
  SettingOutlined,
  ControlOutlined,
  QuestionCircleOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { auth } from "../firebase";

const { Search } = Input;
const { Title } = Typography;

function ProductSourcing() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(20);
  const [checkedList, setCheckedList] = useState([]);
  const [columnOptions, setColumnOptions] = useState([]);
  const [filters, setFilters] = useState({});
  const [minMaxValues, setMinMaxValues] = useState({});
  const [searchText, setSearchText] = useState("");
  const [settingsDrawerVisible, setSettingsDrawerVisible] = useState(false);
  const [filtersDrawerVisible, setFiltersDrawerVisible] = useState(false);
  const [tourOpen, setTourOpen] = useState(false);

  const tourSteps = [
    {
      title: "Settings",
      description:
        "Adjust your table settings, including selecting columns to display.",
      target: document.querySelector(".button-settings"),
    },
    {
      title: "Filters",
      description: "Apply filters to refine your data.",
      target: document.querySelector(".button-filters"),
    },
    {
      title: "Search",
      description: "Search for specific keywords or metrics within the table.",
      target: document.querySelector(".ant-input-search"),
    },
    {
      title: "Sorting",
      description: "Sort the table by clicking on a column header.",
      target: document.querySelector(".ant-table-column-sorters"),
    },
    {
      title: "Products Per Page",
      description: "Adjust the number of products displayed per page.",
      target: () => document.querySelector(".ant-select-selection-item"),
    },
    {
      title: "End of Tour",
      description:
        "You have completed the tour. Enjoy using the Product Sourcing tool!",
      target: document.querySelector(".title-container"),
    },
  ];

  const columnsToFilter = [
    "Price Per Unit ($)",
    "Recommended Sell Price Per Unit",
    "Gross Profit Per Unit ($)",
    "ROI %",
  ];

  const headerTooltips = {
    ASIN: "Amazon's unique product ID.",
    "Price Per Unit ($)": "Cost to purchase one unit.",
    "Recommended Sell Price Per Unit": "Suggested selling price per unit.",
    "Your Estimated Monthly Sales": "Projected units sold monthly.",
    "Gross Profit Per Unit ($)": "Profit per unit after costs.",
    "ROI %": "Return on investment percentage.",
    "Amazon Link": "Link to the product on Amazon.",
    "Source Link": "Supplier link for the product.",
    "KeyWord to search on website":
      "Suggested keyword to search for the product.",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const idToken = await user.getIdToken();
          const response = await fetch(
            "https://bazarr-backend24-11-890728989290.us-central1.run.app/api/product-sourcing",
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
              },
            }
          );
          if (!response.ok) {
            throw new Error("Failed to fetch product sourcing data");
          }
          const jsonData = await response.json();

          const parsedData = jsonData
            .filter((row) =>
              Object.values(row).some((value) => value !== null && value !== "")
            )
            .map((row) => ({
              ...row,
              "Price Per Unit ($)":
                parseFloat(
                  row["Price Per Unit ($)"].toString().replace(/[^0-9.-]/g, "")
                ) || 0,
              "Recommended Sell Price Per Unit":
                parseFloat(
                  row["Recommended Sell Price Per Unit"]
                    .toString()
                    .replace(/[^0-9.-]/g, "")
                ) || 0,
              "Gross Profit Per Unit ($)":
                parseFloat(
                  row["Gross Profit Per Unit ($)"]
                    .toString()
                    .replace(/[^0-9.-]/g, "")
                ) || 0,
              "ROI %":
                parseFloat(row["ROI %"].toString().replace(/[^0-9.-]/g, "")) ||
                0,
            }));

          setData(parsedData);
          generateColumns(Object.keys(parsedData[0]));

          const minMax = {};
          columnsToFilter.forEach((key) => {
            const values = parsedData
              .map((item) => Number(item[key]))
              .filter((value) => !isNaN(value));
            const min = Math.floor(Math.min(...values));
            const max = Math.ceil(Math.max(...values));
            minMax[key] = { min, max };
          });
          setMinMaxValues(minMax);

          const initialFilters = {};
          columnsToFilter.forEach((key) => {
            initialFilters[key] = [minMax[key].min, minMax[key].max];
          });
          setFilters(initialFilters);

          setFilteredData(parsedData);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [auth.currentUser]);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      applyFilters();
    }
  }, [filters, searchText, data]);

  const applyFilters = () => {
    let filtered = data.filter((row) => {
      return (
        rowMatchesSearch(row) &&
        columnsToFilter.every((key) => {
          const value = parseFloat(row[key]);
          if (isNaN(value)) return false;
          return value >= filters[key][0] && value <= filters[key][1];
        })
      );
    });
    setFilteredData(filtered);
  };

  const rowMatchesSearch = (row) => {
    if (!searchText) return true;
    return Object.values(row).some(
      (val) =>
        val &&
        val
          .toString()
          .toLowerCase()
          .includes(searchText.toString().toLowerCase())
    );
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const generateColumns = (headers) => {
    const generatedColumns = headers.map((header) => {
      const key = header;

      const columnTitle = headerTooltips[header] ? (
        <Tooltip title={headerTooltips[header]}>{header}</Tooltip>
      ) : (
        header
      );

      // Special rendering for specific columns
      if (header === "Amazon Link") {
        return {
          title: columnTitle,
          dataIndex: header,
          key,
          render: (value) => (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              className="Amazon Link"
            >
              Amazon Link
            </a>
          ),
        };
      } else if (header === "Source Link") {
        return {
          title: columnTitle,
          dataIndex: header,
          key,
          render: (value) => (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              className="Source Link"
            >
              Supplier Link
            </a>
          ),
        };
      } else if (header === "PHOTO") {
        return {
          title: columnTitle,
          dataIndex: "ASIN",
          key,
          render: (asin) => (
            <img
              src={`../images/${asin}.png`}
              alt={asin}
              className="Product Image"
            />
          ),
        };
      } else if (header === "KeyWord to search on website") {
        return {
          title: columnTitle,
          dataIndex: header,
          key,
          render: (value) => (
            <a
              href={`https://www.google.com/search?q=${value}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {value}
            </a>
          ),
        };
      } else if (header === "ASIN") {
        return {
          title: columnTitle,
          dataIndex: header,
          key,
          fixed: "center",
          width: 100,
        };
      } else {
        return {
          title: columnTitle,
          dataIndex: header,
          key,
          sorter: (a, b) => {
            if (a[header] && b[header]) {
              return a[header].toString().localeCompare(b[header].toString());
            }
            return 0;
          },
          showSorterTooltip: false,
        };
      }
    });

    setColumns(generatedColumns);

    setCheckedList(generatedColumns.map((col) => col.key));

    // Update columnOptions with text labels
    const options = generatedColumns.map((col) => ({
      label: col.key,
      value: col.key,
    }));

    setColumnOptions(options);
  };

  const displayedColumns = columns.filter((col) =>
    checkedList.includes(col.key)
  );

  const handleFilterChange = (field, values) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: values,
    }));
  };

  const handleFilterInputChange = (field, index, value) => {
    const newValues = [...(filters[field] || [0, 0])]; // Provide default value
    newValues[index] = value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: newValues,
    }));
  };

  const resetFilters = () => {
    const resetFilters = {};
    columnsToFilter.forEach((key) => {
      resetFilters[key] = [minMaxValues[key].min, minMaxValues[key].max];
    });
    setFilters(resetFilters);
  };

  return (
    <>
      <div className="title-container">
        <Title level={1} style={{ color: "#006eff" }}>
          <ShoppingCartOutlined className="title-icon" />
          Product Sourcing
        </Title>
      </div>
      <Divider orientation="left">Controls</Divider>
      <div className="product-sourcing-buttons">
        <Button
          className="button-settings"
          type="primary"
          icon={<SettingOutlined />}
          onClick={() => setSettingsDrawerVisible(true)}
        >
          Table Settings
        </Button>

        <Button
          className="button-filters"
          type="primary"
          icon={<ControlOutlined />}
          onClick={() => setFiltersDrawerVisible(true)}
        >
          Filters
        </Button>

        <Button
          className="button-tour"
          type="default"
          onClick={() => setTourOpen(true)}
          icon={<QuestionCircleOutlined />}
        >
          Start Tour
        </Button>
      </div>

      <Search
        className="product-sourcing-search"
        placeholder="Search by any keyword / metric"
        onSearch={handleSearch}
        onChange={(e) => handleSearch(e.target.value)}
        allowClear
        value={searchText}
      />

      <Divider orientation="left">Products</Divider>
      <Table
        className="Table"
        columns={displayedColumns}
        dataSource={filteredData}
        loading={loading}
        pagination={{
          pageSize: pageSize,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
        }}
        rowKey={(record, index) => index}
        scroll={{ x: "max-content" }}
      />

      <Drawer
        title="Table Settings"
        placement="right"
        onClose={() => setSettingsDrawerVisible(false)}
        visible={settingsDrawerVisible}
      >
        <Collapse defaultActiveKey={["1"]}>
          <Collapse.Panel header="Columns to Display" key="1">
            <Select
              mode="multiple"
              defaultValue={checkedList}
              placeholder="Select columns to display"
              options={columnOptions}
              onChange={(list) => setCheckedList(list)}
            />
          </Collapse.Panel>
        </Collapse>
      </Drawer>

      <Drawer
        title="Filters"
        placement="right"
        onClose={() => setFiltersDrawerVisible(false)}
        visible={filtersDrawerVisible}
      >
        <Collapse defaultActiveKey={["1"]}>
          <Collapse.Panel header="Filters" key="1">
            {columnsToFilter.map((field) =>
              filters[field] ? (
                <Form.Item label={field} key={field}>
                  <Row gutter={120}>
                    <Col span={20}>
                      <Slider
                        range
                        min={minMaxValues[field]?.min || 0}
                        max={minMaxValues[field]?.max || 100}
                        value={filters[field]}
                        onChange={(value) => handleFilterChange(field, value)}
                      />
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        min={minMaxValues[field]?.min || 0}
                        max={minMaxValues[field]?.max || 100}
                        value={
                          filters[field]?.[0] || minMaxValues[field]?.min || 0
                        }
                        onChange={(value) =>
                          handleFilterInputChange(field, 0, value)
                        }
                      />
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        min={minMaxValues[field]?.min || 0}
                        max={minMaxValues[field]?.max || 100}
                        value={
                          filters[field]?.[1] || minMaxValues[field]?.max || 0
                        }
                        onChange={(value) =>
                          handleFilterInputChange(field, 1, value)
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              ) : null
            )}
          </Collapse.Panel>
        </Collapse>

        <Form.Item className="product-sourcing-reset-button">
          <Button type="default" onClick={resetFilters}>
            Reset Filters
          </Button>
        </Form.Item>
      </Drawer>
      <Tour
        open={tourOpen}
        onClose={() => setTourOpen(false)}
        steps={tourSteps}
      />
    </>
  );
}

export default ProductSourcing;
