// components/AsinUpcConverter.jsx
import React, { useState } from "react";
import { Input, Button, Typography, message, Divider, Table } from "antd";
import { auth } from "../firebase";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const { Title } = Typography;

function AsinUpcConverter() {
  const [asinInput, setAsinInput] = useState("");
  const [upcInput, setUpcInput] = useState("");
  const [asinToUpcResult, setAsinToUpcResult] = useState([]);
  const [upcToAsinResult, setUpcToAsinResult] = useState([]);
  const [loadingAsinToUpc, setLoadingAsinToUpc] = useState(false);
  const [loadingUpcToAsin, setLoadingUpcToAsin] = useState(false);

  // results tables
  const asinToUpcColumns = [
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
    },
    {
      title: "Error",
      dataIndex: "error",
      key: "error",
      render: (text) => text || "—",
    },
  ];

  const upcToAsinColumns = [
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
    },
    {
      title: "Error",
      dataIndex: "error",
      key: "error",
      render: (text) => text || "—",
    },
  ];

  const parseInput = (input) => {
    return input
      .split(/[,\n\s]+/) // Split by commas, new lines, or spaces
      .map((item) => item.trim())
      .filter((item) => item.length > 0);
  };

  const handleAsinToUpc = async () => {
    const asins = parseInput(asinInput);
    if (asins.length === 0) {
      message.error("Please enter at least one ASIN.");
      return;
    }

    setLoadingAsinToUpc(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        const response = await fetch(
          "https://bazarr-backend24-11-890728989290.us-central1.run.app/api/asin2upc",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              asin: asins.length === 1 ? asins[0] : asins,
            }),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || "Failed to convert ASIN to UPC");
        }

        const data = await response.json();
        setAsinToUpcResult(data.results);
      }
    } catch (error) {
      console.error("Error converting ASIN to UPC:", error.message);
      message.error("Error converting ASIN to UPC");
    } finally {
      setLoadingAsinToUpc(false);
    }
  };

  const handleUpcToAsin = async () => {
    const upcs = parseInput(upcInput);
    if (upcs.length === 0) {
      message.error("Please enter at least one UPC.");
      return;
    }

    setLoadingUpcToAsin(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        const response = await fetch(
          "https://bazarr-backend24-11-890728989290.us-central1.run.app/api/upc2asin",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ upc: upcs.length === 1 ? upcs[0] : upcs }),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || "Failed to convert UPC to ASIN");
        }

        const data = await response.json();
        setUpcToAsinResult(data.results);
      }
    } catch (error) {
      console.error("Error converting UPC to ASIN:", error.message);
      message.error("Error converting UPC to ASIN");
    } finally {
      setLoadingUpcToAsin(false);
    }
  };

  // download table as XLSX
  const downloadTableXLSX = (data, columns, filename) => {
    if (!data || data.length === 0) {
      message.error("No data to download.");
      return;
    }

    // Convert data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Results");

    // Create a buffer and save the file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(excelBlob, `${filename}.xlsx`);
  };

  // download table as CSV
  const downloadTableCSV = (data, columns, filename) => {
    if (!data || data.length === 0) {
      message.error("No data to download.");
      return;
    }

    // Convert data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Results");

    // Convert worksheet to CSV and save the file
    const csv = XLSX.utils.sheet_to_csv(worksheet);
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(csvBlob, `${filename}.csv`);
  };

  return (
    <div className="title-container">
      <Title level={1} style={{ color: "#006eff" }}>
        ASIN ↔ UPC Converter
      </Title>

      {/*Convert ASIN to UPC */}
      <Divider orientation="left">Convert ASIN to UPC</Divider>
      <Input.TextArea
        rows={4}
        placeholder="Enter ASINs separated by commas, spaces, or new lines"
        value={asinInput}
        onChange={(e) => setAsinInput(e.target.value)}
        style={{ marginBottom: "16px" }}
      />
      <Button
        type="primary"
        onClick={handleAsinToUpc}
        loading={loadingAsinToUpc}
        style={{ marginBottom: "24px" }}
      >
        Convert to UPC
      </Button>

      {/* Display ASIN to UPC conversion results */}
      {asinToUpcResult.length > 0 && (
        <>
          <Divider orientation="left">ASIN to UPC Results</Divider>
          <Table
            dataSource={asinToUpcResult.map((item, index) => ({
              ...item,
              key: index,
            }))}
            columns={asinToUpcColumns}
            pagination={false}
            bordered
          />
          <div style={{ marginTop: "16px" }}>
            <Button
              type="default"
              onClick={() =>
                downloadTableXLSX(
                  asinToUpcResult,
                  asinToUpcColumns,
                  "ASIN_to_UPC"
                )
              }
              style={{ marginRight: "8px" }}
            >
              Download as XLSX
            </Button>
            <Button
              type="default"
              onClick={() =>
                downloadTableCSV(
                  asinToUpcResult,
                  asinToUpcColumns,
                  "ASIN_to_UPC"
                )
              }
            >
              Download as CSV
            </Button>
          </div>
        </>
      )}

      <Divider />

      {/* Convert UPC to ASIN */}
      <Divider orientation="left">Convert UPC to ASIN</Divider>
      <Input.TextArea
        rows={4}
        placeholder="Enter UPCs separated by commas, spaces, or new lines"
        value={upcInput}
        onChange={(e) => setUpcInput(e.target.value)}
        style={{ marginBottom: "16px" }}
      />
      <Button
        type="primary"
        onClick={handleUpcToAsin}
        loading={loadingUpcToAsin}
        style={{ marginBottom: "24px" }}
      >
        Convert to ASIN
      </Button>

      {/* Display UPC to ASIN conversion results */}
      {upcToAsinResult.length > 0 && (
        <>
          <Divider orientation="left">UPC to ASIN Results</Divider>
          <Table
            dataSource={upcToAsinResult.map((item, index) => ({
              ...item,
              key: index,
            }))}
            columns={upcToAsinColumns}
            pagination={false}
            bordered
          />
          <div style={{ marginTop: "16px" }}>
            <Button
              type="default"
              onClick={() =>
                downloadTableXLSX(
                  upcToAsinResult,
                  upcToAsinColumns,
                  "UPC_to_ASIN"
                )
              }
              style={{ marginRight: "8px" }}
            >
              Download as XLSX
            </Button>
            <Button
              type="default"
              onClick={() =>
                downloadTableCSV(
                  upcToAsinResult,
                  upcToAsinColumns,
                  "UPC_to_ASIN"
                )
              }
            >
              Download as CSV
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default AsinUpcConverter;
