import React from "react";
import { Layout } from "antd";

function Welcome() {
  return (
    <Layout>
      <div>Welcome to the Dashboard</div>
    </Layout>
  );
}

export default Welcome;
