import React from "react";
import { Typography, Button, Divider } from "antd";

const { Title, Text } = Typography;

function Confirmation({ email }) {
  return (
    <div className="confirmation-container">
      <div className="title-container">
        <Title level={1}>Success!</Title>
        <Divider />
      </div>
      <Text>
        The file will be sent to <strong>{email}</strong>.
      </Text>
      <br />
      <Text>
        Estimated results time is between an hour and 24 hours, depending on the
        ASINs/UPCs amount.
      </Text>
      <br />
      <Button
        className="confirmation-back-home-button "
        type="primary"
        href="/"
      >
        Back to Home
      </Button>
    </div>
  );
}

export default Confirmation;
